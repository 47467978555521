/** @jsx jsx */
import { css, jsx } from "@emotion/react";
// import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Configure, Index } from "react-instantsearch-hooks-web";

import SearchHit from "./SearchHit";
import SearchHits from "./SearchHits";
import SearchPagination from "./SearchPagination";
import SearchProvider from "./SearchProvider";

ContentTypeArchive.propTypes = {};

export default function ContentTypeArchive({ contentType }) {
  const { t, i18n } = useTranslation();

  return (
    <SearchProvider routing={true} skipSearchIf={() => false}>
      {({ indexName }) => (
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
          `}
        >
          <Configure filters={`language:${i18n.language}`} />
          <Configure filters={`contentType.name:${contentType.name}`} />
          <Configure sortBy={`${indexName}_publish_date`} />
          <SearchHits hitComponent={SearchHit} />
          <SearchPagination />
        </div>
      )}
    </SearchProvider>
  );
}
